import request from '@/utils/request'
import { API_PRIFIX } from '@/constances/server'
const source = {
  [`设计师`]: 'designer',
  [`列表`]: 'index',
  [`详情`]: 'showDesigner',
  [`服务类型`]: 'serviceType',
  [`擅长`]: 'style',
  [`设计师评论`]: 'designerComment',
  [`获取关注状态`]: 'getFollowStatus',
  [`改变关注状态`]: 'changeFollowStatus',
  [`预约设计师`]: `designAppointment`,
}

export function getDesigners(params) {
  return request({
    url: `${API_PRIFIX}/${source[`设计师`]}/index`,
    method: 'get',
    params
  })
}

export function getDesigner({id}) {
  return request({
    url: `${API_PRIFIX}/${source[`设计师`]}/${source[`详情`]}/${id}`,
    method: 'get',
  })
}

//关注设计师-列表
export function getFollowIndex(params) {
  return request({
    url: `${API_PRIFIX}/${source[`设计师`]}/followIndex`,
    method: 'get',
    params
  })
}

export function getMyDesignerInfo() {
  return request({
    url: `${API_PRIFIX}/${source[`设计师`]}/show`,
    method: 'get',
  })
}

export function getDesignerServiceTypes(params) {
  return request({
    url: `${API_PRIFIX}/${source[`设计师`]}/${source[`服务类型`]}/index`,
    method: 'get',
    params
  })
}

export function getDesignerStyles(params) {
  return request({
    url: `${API_PRIFIX}/${source[`设计师`]}/${source[`擅长`]}/index`,
    method: 'get',
    params
  })
}

export function getDesignerComments(params) {
  return request({
    url: `${API_PRIFIX}/${source[`设计师评论`]}`,
    method: 'get',
    params
  })
}

export function addDesignerComments(data) {
  return request({
    url: `${API_PRIFIX}/${source[`设计师评论`]}`,
    method: 'post',
    data
  })
}

export function getDesignerFollowStatus({id}) {
  return request({
    url: `${API_PRIFIX}/${source[`设计师`]}/${source[`获取关注状态`]}/${id}`,
    method: 'get',
  })
}

export function designerFollowStatus({id}) {
  return request({
    url: `${API_PRIFIX}/${source[`设计师`]}/${source[`改变关注状态`]}/${id}`,
    method: 'post',
  })
}

    // 获取设计师擅长列表
    export function designerStyleList(params) {
      return request({
        url: `${API_PRIFIX}/${source[`设计师`]}/style/index`,
        method: 'get',
        params
      })
    }

    // 获取设计师服务类型列表
    export function designerServiceTypeList(params) {
      return request({
        url: `${API_PRIFIX}/${source[`设计师`]}/serviceType/index`,
        method: 'get',
        params
      })
    }

    // 获取设计师详情
    export function designerDetail(params) {
      return request({
        url: `${API_PRIFIX}/${source[`设计师`]}/show`,
        method: 'get',
        params
      })
    }

    
    // 更新设计师
    export function designerUpdate(data) {
      return request({
        url: `${API_PRIFIX}/${source[`设计师`]}/update`,
        method: 'PATCH',
        data
      })
    }

    // 预约设计师 - 添加
    export function addDesignAppointments(data) {
      return request({
        url: `${API_PRIFIX}/${source[`预约设计师`]}`,
        method: 'post',
        data
      })
    }

    // 轮播图列表
export function getBanner(params) {
  return request({
    url: `/pc/designBanner`,
    method: 'get',
    params
  })
}

    //设计师vip充值
    export function designerRecharge(data) {
      return request({
        url: `${API_PRIFIX}/designerRecharge`,
        method: 'post',
        data
      })
    }

    //查询设计师vip充值订单
    export function getDesignerRecharge({id}) {
      return request({
        url: `${API_PRIFIX}/designerRecharge/${id}`,
        method: 'get',
      })
    }    


    //查询设计师vip充值成功订单
    export function getInvestList(params) {
      return request({
        url: `${API_PRIFIX}/designerRecharge`,
        method: 'get',
        params
      })
    }    
